@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 50%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}

body a {
  text-decoration: none;
  list-style: none;
  cursor: none !important;
}


@media only screen and (min-width: 768px) {
  html {
    font-size: 60%;

  }

  body a {
    text-decoration: none;
    list-style: none;
    cursor: pointer !important;
  }

}