.Container {
  width: 65%;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #127452;
  border: none;
}

.Input {
  font-size: 2rem !important;
  color: #ffffff !important;
  border-radius: 0, 9rem;
  border: none;
  outline: none;
}

.Clear {
  color: white !important;
}
