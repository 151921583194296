.container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-top: 0;
}

.infoList {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.infoList li {
  margin-bottom: 10px;
}

.infoList i {
  font-size: 18px;
  margin-right: 10px;
}

p {
  line-height: 1.5em;
  margin-bottom: 20px;
}
