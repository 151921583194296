.NavItem {
  font-weight: 500;
  color: black;
  justify-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.9rem;
  padding: 1.5rem;
  padding-bottom: 2rem;
}

.Container:hover {
  background-color: rgb(255, 253, 253);
}

.NavItemActive {
  background-color: rgb(255, 253, 253);
}

.NavIcon {
  margin-right: 2rem;
}