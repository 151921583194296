.Container {
  background-color: #e3f6f5;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  bottom: 0;
  width: 100%;
}
