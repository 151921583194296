.Container {
  background-color: #e3f6f5;
  /* height: 7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 3rem; */
  margin-bottom: 4rem;
  /* position: fixed;
  z-index: 99909; */
  width: 100%;
  /* padding-top: 6rem; */
}

.Container img {
  width: 15rem;
}
