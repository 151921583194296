.Container {
  padding-top: 5rem;
}

.DataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  background-color: #272343;
  padding: 2rem;
  color: white;
  border-radius: 0.9rem;
}

.DataContainer p {
  font-size: 2rem;
}
