.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  flex-direction: column;
  font-size: 2rem;
  gap: 2rem;
}

.Input {
  font-size: 2rem;
}

.button {
  font-size: 2.4rem;
  padding: 2rem;
  background-color: #127452;
  color: #ffffff;
  font-family: "Roboto";
  border: none;
  border-radius: 0.9rem;
  width: 100%;
}
