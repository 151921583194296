.Submit {
  background-color: #127452;
  padding: 2rem;
  color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: none;
  border-radius: 0.9rem;
  padding-left: 8rem;
  padding-right: 8rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 2rem;
}

.PriceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #127452;
  border-radius: 0.9rem;
  color: #ffffff;
  padding: 2rem;
}

.PriceContainer p {
  padding: 1rem;
  font-size: 2.4rem;
  font-weight: 500;
}

.PriceContainer h2 {
  color: #ffd803;
}

.Message {
  font-size: 2.6rem !important;
  z-index: 99999999 !important;
}
