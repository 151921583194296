.InputGroup input,
textarea {
  border: 0.3rem solid black;
  border-radius: 0.9rem;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

.InputGroup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}

.InputGroup .QuantityField {
  position: relative;
  height: 38px;
  left: -2px;
  text-align: center;
  width: 62px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
  background: none;
  color: white;
  font-size: 1.6rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.Icon {
  color: #ffd803;
}
