.Container {
  background-color: #127452;
  padding: 3rem;
  color: white;
  font-size: 2.6rem;
  border-radius: 0.9rem;
  display: flex;
  flex-direction: column !important;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  min-height: 40rem;
  text-align: center;
}

.Spans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.Icon {
  color: #00b4ff;
  cursor: pointer;
  font-size: 3.4rem;
}

.inCart {
  display: flex;
  justify-content: center;
  gap: 3rem;
  width: 100%;
  align-items: center;
}
