.App {
  padding-bottom: 20rem;
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SpinnerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(238, 238, 238, 0.322);
}

.Spinner {
  color: #127452;
}
