.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

.Buttons {
  display: flex;
  justify-content: center;
  gap: 4rem;
  align-items: center;
}

.Button {
  font-size: 3rem !important;
  margin-bottom: 3rem !important;
}

.Message {
  font-size: 2rem;
  color: black;
  margin-bottom: 2rem;
}
