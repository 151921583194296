.Container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ButtonContainer a {
  margin-right: 2rem;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  line-height: 1.5em;
  margin-bottom: 20px;
}

.List {
  list-style: none;
  padding: 0;
  margin: 0;
}

.List li {
  margin-bottom: 10px;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}

.Button {
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
