.Container {
  background-color: #127452;
  padding: 2rem;
  color: white;
  font-size: 2.1rem;
  border-radius: 0.9rem;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.InfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ButtonA {
}
.Container p {
  padding: 2rem;
}

.Button {
  cursor: pointer;
  padding: 1rem;
  font-size: 1.9rem;
  font-weight: 600;
  background: none;
  border: none;
  color: white;
  color: yellow;
}

.DetailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  text-align: end;
  gap: 2rem;
}

.Container th,
.Container td {
  color: white !important;
  font-size: 1.9rem;
}

.Container table {
  background-color: #127452;
  color: white !important;
  border: none;
}
