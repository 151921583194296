.Container {
  padding-top: 2rem;
}

.Container th,
.Container td {
  color: white !important;
  font-size: 1.9rem;
}

.Container table {
  background-color: #272343;
  color: white !important;
  border: none;
}

.Count {
  padding: 3rem;
  background-color: #272343;
  margin-bottom: 4rem;
  color: white;
  text-align: center;
  font-size: 2rem;
  border-radius: 0.7rem;
}
